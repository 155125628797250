@charset "UTF-8";

@import "../node_modules/primeng/resources/themes/lara-light-blue/theme.css";
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeicons/primeicons.css";

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #050505;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: var(--main-color-white);
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #050505;
  --bs-light: var(--main-color-background);
  --bs-dark: #343a40;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  font-family: var(--main-font-family);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: var(--main-color-white);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow-x: hidden;

  span {
    font-family: var(--main-font-family);
    //color: var(--gray-secondary-color);
  }

  p {
    color: var(--main-color-black);
  }

  h6,
  .h6,
  h5,
  .h5,
  h4,
  .h4,
  h3,
  .h3,
  h2,
  .h2,
  h1,
  .h1 {
    margin-top: 0;
    margin-bottom: 5px;
    font-weight: 500;
    line-height: 1.2;
  }

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--main-color);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px #d8d6d6;
    border-radius: 5px;
  }
}

app-root {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.overflow-hidden {
  overflow: hidden !important;

  .overlay-shadow {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 19;
    opacity: 0.5;
    background-color: var(--main-color-black);
    transition: 0.3s ease-out;
  }
}

hr {
  margin: 10px 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

button {
  cursor: pointer;
  border-radius: 6px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  line-height: 1.6;
  height: 40px;
  font-size: 0.875rem;

  span {
    color: var(--main-color-white);
  }
}

.button-rm {
  padding: 4px 15px !important;
  border: 2px solid var(--main-color) !important;
  background: var(--main-color) !important;
  color: var(--main-color-white);

  &:hover {
    background: var(--main-color-black) !important;
    color: var(--main-color-white) !important;
  }
}

.button-rm-2 {
  padding: 4px 15px !important;
  border: 2px solid var(--main-color) !important;
  background: var(--gray-primary-color) !important;
  color: var(--main-color-white);

  &:hover {
    background: var(--main-color) !important;
  }
}

.p-button-rm {
  button {
    background-color: var(--main-color);
    border: none;

    .p-button-icon-left {
      margin: 0;
      color: var(--main-color-black);
      font-weight: 600;
    }

    &:enabled:hover {
      background-color: var(--main-color-black);

      .p-button-icon-left {
        color: var(--main-color-white);
      }
    }
    &:focus {
      box-shadow: none;
    }
  }
}

.error-input {
  border-color: var(--main-color-error) !important;
}

.error-input-prime {
  input {
    border-color: var(--main-color-error) !important;
  }
}

input {
  width: 100%;
  height: 40px;
}

.prime-button-rm {
  button {
    border-radius: 6px !important;
    background: var(--main-color);
    transition-duration: 0.2s;
    border: none;

    .p-button-label {
      font-size: 0.875rem !important;
      font-weight: 600 !important;
    }

    &:hover {
      background: var(--main-color-black) !important;
      border: 2px solid var(--main-color) !important;

      span {
        color: var(--main-color-white) !important;
      }
    }

    &:focus {
      box-shadow: none;
    }
  }
}

.default-search-style-input-box {
  padding: 5px;
  border-radius: 5px;
  font-size: 0.8125rem;
  box-shadow: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

form {
  .p-float-label input:focus ~ label,
  .p-float-label input.p-filled ~ label,
  .p-float-label textarea:focus ~ label,
  .p-float-label textarea.p-filled ~ label,
  .p-float-label .p-inputwrapper-focus ~ label,
  .p-float-label .p-inputwrapper-filled ~ label,
  .p-float-label p-dropdown:focus ~ label,
  .p-float-label p-dropdown.p-filled ~ label {
    top: -5px;
    font-weight: 600;
    font-size: 0.75rem;
  }

  .error-p-float-label {
    label {
      color: var(--main-color-error) !important;
    }
  }
}

input {
  &::-webkit-input-placeholder {
    /* WebKit browsers */
    text-transform: none;
  }
  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    text-transform: none;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    text-transform: none;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    text-transform: none;
  }
  &::placeholder {
    /* Recent browsers */
    text-transform: none;
  }
}

p-radiobutton {
  .p-radiobutton {
    .p-radiobutton-box.p-highlight {
      border-color: var(--main-color) !important;
      background: var(--main-color) !important;

      span {
        background: var(--gray-primary-color) !important;
      }
    }

    .p-radiobutton-box:hover {
      border-color: var(--main-color) !important;
    }

    .p-focus {
      box-shadow: none !important;
      border-color: #ced4da !important;
    }
  }
}

p-inputMask {
  input {
    &:hover {
      border-color: var(--main-color) !important;
    }

    &:focus {
      box-shadow: none !important;
      border-color: var(--main-color) !important;
    }
  }

  &.error-input-mask {
    input {
      border-color: var(--main-color-error) !important;
    }
  }
}

.p-inputtext {
  &:hover {
    border-color: var(--main-color) !important;
  }

  &:focus {
    box-shadow: none !important;
    border-color: var(--main-color) !important;
  }
}

p-inputnumber {
  input {
    &:hover {
      border-color: var(--main-color) !important;
    }

    &:focus {
      box-shadow: none !important;
      border-color: var(--main-color) !important;
    }
  }
}

p-toast {
  .p-toast {
    opacity: 1;
    white-space: pre-line;
    max-height: 100px;

    .p-toast-message {
      background-color: #fff;
      .p-toast-message-content {
        background-color: #fff;
        .p-toast-detail {
          background-color: #fff;
          max-height: 75vh;
          overflow: auto;
          overflow-wrap: anywhere;
        }
      }
    }
  }
}

p-dropdown {
  .p-dropdown {
    .p-dropdown-label {
      height: 38px;
      display: flex;
      align-items: center;
      font-size: 0.875rem;
    }

    p-overlay {
      .p-overlay {
        .p-overlay-content {
          .p-dropdown-panel {
            .p-dropdown-items-wrapper {
              ul {
                padding: 0;

                p-dropdownitem {
                  li {
                    padding: 5px 10px;
                    span {
                      font-size: 0.8125rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &:hover {
      border-color: var(--main-color) !important;
    }
  }
  .p-focus {
    box-shadow: none !important;
    border-color: var(--main-color) !important;
  }
}

p-paginator {
  .p-paginator {
    background: var(--main-color-background);

    button {
      border-radius: 24px;
      height: 3rem;
    }

    .p-paginator-pages {
      .p-paginator-page {
        text-align: center;
      }

      .p-paginator-page.p-highlight {
        background: var(--main-color) !important;
        color: var(--gray-primary-color) !important;
        border: none;
        box-shadow: none;
      }
    }
  }
}

p-progressSpinner {
  text-align: center;

  .p-progress-spinner-circle {
    stroke: var(--main-color) !important;
  }
}

p-dialog {
  .p-dialog-header {
    .p-dialog-header-icons {
      button {
        color: var(--gray-primary-color);
      }
    }
  }
}

@media (max-width: 640px) {
  p-paginator {
    .p-paginator {
      padding: 0;

      .p-paginator-first,
      .p-paginator-prev,
      .p-paginator-next,
      .p-paginator-last {
        min-width: 2rem !important;
        height: 2rem !important;
        padding: 0 !important;
        text-align: center !important;

        span {
          font-size: 0.75rem !important;
        }
      }

      .p-paginator-pages {
        .p-paginator-page {
          min-width: 2rem !important;
          height: 2rem !important;
          font-size: 0.75rem !important;
          padding: 0 !important;
        }
      }
    }
  }
}
